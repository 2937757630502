import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import { useEffect, useState } from 'react'
import './testimonial.css'
import User_photo from '../../images/testimonials/user-img.png'
import prev from '../../images/testimonials/navprev.svg'
import next from '../../images/testimonials/navnext.svg'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import Loader from '../loader'

import ReactHtmlParser from 'react-html-parser'

import { testimonial_api } from './api'

const Testimonials = () => {
  const [testimonial, setTestimonial] = useState([])
  const [loading, setLoading] = useState(false)
  var options = {
    margin: -18,
    responsiveClass: true,
    nav: testimonial?.length >= 2 ? true : false,
    navText: [
      testimonial?.length >= 2 ? `<img src = ${prev} alt = "navprev">` : null,
      testimonial?.length >= 2 ? `<img src = ${next} alt = "navnext">` : null
    ],
    dots: testimonial?.length >= 2 ? true : false,
    autoplay: false,
    loop: testimonial?.length >= 2 ? true : false,
    center: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
        margin: 10
      },
      400: {
        items: 1,
        margin: 10
      },
      600: {
        items: 1,
        margin: 10
      },
      700: {
        items: 1,
        margin: 10
      },
      993: {
        items: 3,
        margin: -16
      },
      1201: {
        items: 3
      }
    }
  }

  // Use Effect Section
  useEffect(() => {
    getTestimonial()
  }, [])

  //  End of Use Effect Section

  // User Define Function Section

  const getTestimonial = async () => {
    setLoading(true)
    testimonial_api?.getTestimonial((err, data) => {
      console.log(err)
      console.log(data?.data)
      if (err == null) {
        console.log(data?.data)
        const testimonialsdata = data?.data?.data?.map(item => {
          const fulldescription = ReactHtmlParser(item?.content)?.[0]?.props
            ?.children?.[1]
          console.log(ReactHtmlParser(item?.content)?.[0]?.props?.children?.[1])
          console.log(fulldescription)

          return {
            ...item,
            image: `https://navigatemarinecms.mwancloud.com/${item?.image}`,
            description:
              fulldescription?.split(' ')?.length > 50
                ? fulldescription?.slice(0, 100) + '...'
                : fulldescription
          }
        })
        setTestimonial(testimonialsdata)
      } else {
        setTestimonial([])
      }
      setLoading(false)
    })
  }

  //   End of User Define Function
  return (
    <section className='testimonial'>
      <Container>
        <h1 className='text-center'>Testimonials</h1>
        <Row>
          <Col className={`${loading == true ? 'loader-section' : ''}`} xs={12}>
            {loading == true ? (
              <Loader />
            ) : (
              <OwlCarousel className='slider-items owl-carousel' {...options}>
                {testimonial?.map(item => {
                  return (
                    <div className='testimonial-card item'>
                      <div className='user-img'>
                        <img src={item?.image} alt='user' />
                      </div>
                      <div className='testimonial-content'>
                        <p>{item?.description}</p>
                      </div>
                      <div className='user-name text-center'>
                        <h3>{item?.name}</h3>
                        <div className='company'>{item?.designation}</div>
                      </div>
                    </div>
                  )
                })}
              </OwlCarousel>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Testimonials
