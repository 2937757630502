import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import '../components/terms-services/terms-services.css'

import TermsServices from '../components/terms-services/terms-services-banner'
import { useEffect } from 'react'
const Cookiespolicy = () => {
  useEffect(() => {
    window?.scrollTo(0, 0)
  }, [])
  return (
    <section className='main'>
      <section className='terms-sec'>
        <Container>
          <Row>
            <TermsServices title={'Cookies Policy'} />
          </Row>

          <Row>
            <section className='terms-content cookies_policy'>
              <h2>
                Navigate Commodities makes use of cookies and similar
                technologies throughout our websites to ensure your visit to our
                website goes smoothly.
              </h2>

              <h2>What are the Cookies</h2>

              <p>
                Cookies (and similar technologies) are widely used on the
                internet and allow a website/portal to recognise a user’s
                device, without uniquely identifying the individual person using
                the computer. Cookies are small files which are stored on a
                user’s computer. They are designed to hold a modest amount of
                data specific to a particular client or website. A cookie is
                sent by a web server to a web browser that enables the server to
                collect information back from the browser. This enables the site
                to recognize your computer each time you visit. These
                technologies help to make it easier for you to log on and use
                our websites and provide information to us, for example which
                parts of the website you visit.
              </p>

              <p>
                For more information about cookies, including how to see what
                cookies have been set and how to manage, block and/or delete
                them, please refer to the below information about our Cookie
                Policy.
              </p>

              <h2>Cookies we bake and use:</h2>

              <p>
                <strong>1. Strictly necessary cookies.</strong>
                <br />
                These are cookies that are required for the operation of our
                website and our Apps. They include, for example, cookies that
                enable you to log into secure areas of our website, access gated
                articles or make use of e-billing services.
                <br />
                <strong>2. Analytical/performance cookies.</strong>
                <br />
                These cookies allow us to recognise and count the number of
                visitors and to see how visitors move around our website and
                when they are using them. This helps us to improve the way our
                website and our Apps work, for example, by ensuring that users
                are finding what they are looking for easily.
                <br />
                <strong>3. Functionality cookies.</strong>
                <br />
                These are used to recognise you when you return to our website.
                These enable us to personalise our content for you, greet you by
                name and remember your preferences.
                <br />
                This website contains cookies from third-party websites, mainly
                social media cookies. When placed on your computer, they
                automatically activate handy extras, for example, a Facebook
                ‘like’ button or a Twitter messaging option. These cookies
                inform our website whether you are logged into such social media
                and they also enable you to share parts of this website on
                social media. When visiting this website, Payvision will ask for
                your consent to use these cookies.
              </p>

              <h2>Do you object to cookies?</h2>

              <p>
                Most web browsers automatically accept cookies, but you can
                usually modify your browser setting to decline cookies if you
                prefer. This may prevent you from taking full advantage of the
                website.
              </p>
            </section>
          </Row>
        </Container>
      </section>
    </section>
  )
}

export default Cookiespolicy
