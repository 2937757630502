// import { NavLink, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container'

import Innertopbanner from '../components/inner-topbanner/Innertopbanner'
import DataFeatures from '../components/data-features/DataFeatures'
import Services from '../components/services/Services'
import Demobanner from '../components/demobanner/Demobanner'

import SupplyDemand from '../images/product/Compass-1.svg'
import SatelliteData from '../images/product/Compass-2.svg'
import Miningdata from '../images/product/Compass-3.svg'
import Market_compass from '../images/inner-banner/Desktop.svg'
import React, { useEffect } from 'react'
const MarketCompass = () => {
  useEffect(() => {
    window?.scrollTo({ top:0, left:0, behavior: "instant"})
  }, [])

  return (
    <section className='main'>
      <section className='main-banner inner-banner'>
        <Container className='text-center text-md-start'>
          <Innertopbanner
            header={'Market Compass'}
            pradetails={
              'Weekly Ferrous Dry Bulk analytics report and forecast to send every Monday before market opens. With 81% accuracy on the short-term forecasts, we act as your market compass to help you navigate the volatile commodity markets.'
            }
            image={
              <img
                className='innerbanner-img navigate_compass'
                src={Market_compass}
                alt='banner_graphics'
              />
            }
          />
        </Container>
      </section>

      <section className='solution data-features py-4 text-center'>
        <DataFeatures
          header={'Data and Features'}
          Pradetails={
            'Get ahead of the competition with our expert weekly futures market analysis, featuring a detailed recap and summary, short-term forcast, and medium-term outlook'
          }
        />
      </section>

      <section className='middle-top market_compass py-3'>
        <Container className='text-cetner text-md-start'>
          <Services
            image={SupplyDemand}
            header={'Weekly Market Recap'}
            pra={
              'Stay informed with our weekly market summary and price trend analysis for the ferrous dry bulk commodities sector.'
            }
          />
          <Services
            image={SatelliteData}
            header={'Short-term Forecasts'}
            pra={
              'Empower your trading strategies with our short-term forecast that boast an industry-leading 81% accuracy and stay ahead of your competition.'
            }
          />
          <Services
            image={Miningdata}
            header={'Medium-term Outlook'}
            pra={
              'Understand the bigger picture with our medium-term outlook, featuring an in-depth analysis of the macro & microeconomic events surrounding the industry.'
            }
          />
        </Container>
      </section>

      <Demobanner />
    </section>
  )
}

export default MarketCompass
