// import { NavLink, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container'

import Innertopbanner from '../components/inner-topbanner/Innertopbanner'
import DataFeatures from '../components/data-features/DataFeatures'
import Services from '../components/services/Services'
import Demobanner from '../components/demobanner/Demobanner'

import SupplyDemand from '../images/product/Navi-1.svg'
import SatelliteData from '../images/product/Navi-2.svg'
import Miningdata from '../images/product/Navi-3.svg'
import NAVI_whatsapp from '../images/inner-banner/Whatsapp.svg'
import React, { useEffect } from 'react'
const NaviWhatsapp = () => {
  useEffect(() => {
    window?.scrollTo({ top:0, left:0, behavior: "instant"})

  }, [])
  return (
    <section className='main'>
      <section className='main-banner inner-banner'>
        <Container className='text-center text-md-start'>
          <Innertopbanner
            header={'NAVI WhatsApp AI'}
            pradetails={
              "NAVI is the world's first WhatsApp-native AI commodity analytics bot for the Ferrous Dry Bulk markets. Query trade flows & production trends for any country - on-demand."
            }
            image={
              <img
                className='innerbanner-img navigate_whatsapp'
                src={NAVI_whatsapp}
                alt='banner_graphics'
              />
            }
          />
        </Container>
      </section>

      <section className='solution data-features py-4 text-center'>
        <DataFeatures
          header={'Data and Features'}
          Pradetails={
            'The Convenience of our AI chatbot allows for easy access to analytics charts, providing you with up-to-date and relaible data in seconds.'
          }
        />
      </section>

      <section className='middle-top navigate_whatsapp py-3'>
        <Container className='text-cetner text-md-start'>
          <Services
            image={SupplyDemand}
            header={'On-Demand 24/7'}
            pra={
              'Whether it’s 3PM or 3AM, NAVI generates your commodity intelligence on-demand. No sleep, no backchat, and no huffing & puffing.'
            }
          />
          <Services
            image={SatelliteData}
            header={'Five-Year Trade Data'}
            pra={
              'Lookup 5-year historical import or export information for various ferrous dry bulk commodities between countries.'
            }
          />
          <Services
            image={Miningdata}
            header={'Seasonality Trend'}
            pra={
              'Understand the peak and troughs of the market across the year to plan your business strategy.'
            }
          />
        </Container>
      </section>

      <Demobanner />
    </section>
  )
}

export default NaviWhatsapp
