import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Link } from 'react-router-dom'

const Mediapost = ({ header, title, pdate, image, link }) => {
  return (
    <Row className='media-post-row'>
      <Col sm={12} className='media-col'>
        <Row>
          <Col lg={8} className='mb-3 mb-lg-0 order-last order-md-first'>
          <Link to={link} style={{textDecoration:'none'}}> <h3>{header}</h3></Link>
            <Link to={link} style={{textDecoration:'none'}}><p>{title}</p></Link>
            <div className='post-date'>{pdate}</div>
            <Link to={link} className='btn-main'>
              View Now
            </Link>
          </Col>
          <Col lg={4} className='order-first order-md-last mb-3 mb-md-0'>
          <Link to={link} >
            <div className='media-img'>
              <img src={image} alt='media' />
            </div>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Mediapost
