import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import React, { useState } from 'react'

import './about.css'
import Atilla from '../../images/about/atillaWidnell.png'
import Lee from '../../images/about/leeGoe.png'
import Gui from '../../images/about/zhangGui.png'
import LinkdIn_ic from '../../images/about/linkdIn_ic.svg'
import Modal_atilla from '../../images/about/modal_atilla.png'
import { useParams } from 'react-router-dom'
const Leadership = () => {
  const leadershipdata = [
    {
      name: 'Atilla Widnell',
      post: 'Managing Director, Singapore',
      img: Atilla,
      desc: 'Prior to founding Navigate Commodities, Atilla has held executive and senior management positions at tier-1 commodity market intelligence agencies over the past 12 years, including CRU, Metal Bulletin Research (FastMarkets) and Mysteel Global.',
      ll: 'https://www.linkedin.com/in/atillawidnell/'
    },
    {
      name: 'Lee Gao Rui',
      img: Lee,
      post: 'Director, Singapore',
      desc: 'Gao Rui brings almost seven years of commodity market intelligence-specific marketing & operations experience from Argus Media to Navigate Commodities, as well as re-forming a large portion of the original award-winning Mysteel Global leadership team.',
      ll: 'https://www.linkedin.com/in/gaorui-lee/'
    },
    {
      name: 'Zhang Gui',
      img: Gui,
      post: 'Director, Shanghai',
      desc: 'Gui was an invaluable founding member of the award-winning Mysteel Global leadership team, leading operations, executing strategy and implementing technological solutions in Shanghai, China.',
      ll: 'https://www.linkedin.com/in/gui-zhang-36ab1a145/'
    }
  ]
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const [currentdetails, setCurrentDetails] = useState(leadershipdata[0])
  const handleShow = index => {
    setCurrentDetails(leadershipdata[index])
    setShow(true)
  }
  const { id } = useParams()
  React.useEffect(() => {
    console.log(id)
    if (id != null && id != undefined) {
      document.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth' })
    }
  }, [id])

  return (
    <>
      <section className='Leadership' id='leadership'>
        <Container>
          <h2 className='text-center'>Our Leadership</h2>
          <Row>
            {leadershipdata?.map((item, i) => {
              return (
                <Col md={4}>
                  <div
                    className='leadership-card'
                    onClick={() => {
                      handleShow(i)
                    }}
                  >
                    <div className='member-image'>
                      <img src={item?.img} alt='leadership-img' />
                    </div>
                    <div className='member-details'>
                      <div className='member-name'>{item?.name}</div>
                      <div className='member-post'>{item?.post}</div>
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
        <Modal
          size='lg'
          show={show}
          onHide={handleClose}
          className='team_modal'
          centered
        >
          <Modal.Body>
            <Row>
              <Col md={6} lg={5}>
                <div className='user-img'>
                  <img src={currentdetails?.img} alt='leadership-img' />
                </div>
              </Col>
              <Col md={6} lg={7}>
                <div className='user-name'>{currentdetails?.name}</div>
                <div className='user-posiiton'>{currentdetails?.post}</div>
                <div className='user-details'>{currentdetails?.desc}</div>
                <div className='user_scial_links'>
                  <a href={currentdetails?.ll} target='_blank'>
                    <img src={LinkdIn_ic} alt='social-icon' />
                  </a>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default Leadership
