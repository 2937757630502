import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from 'react'
import './client-carousel.css'
import Marquee from 'react-fast-marquee'
import BNP_logo from '../../images/new-client-logo/20-2.png'
import CBank_logo from '../../images/new-client-logo/1-2.png'
import Gold_man from '../../images/new-client-logo/6-2.png'
import SGX_logo from '../../images/new-client-logo/12-2.png'
import Anglo_logo from '../../images/new-client-logo/18-2.png'
import CELSA_group from '../../images/new-client-logo/2-2.png'
import UOB_logo from '../../images/new-client-logo/15-2.png'
import CTIC_logo from '../../images/new-client-logo/3-2.png'
import emr_logo from '../../images/new-client-logo/4-2.png'
import Engelhart_logo from '../../images/new-client-logo/5-2.png'
import gsmt_logo from '../../images/new-client-logo/7-2.png'
import Kroman_logo from '../../images/new-client-logo/8-2.png'
import Megasa_logo from '../../images/new-client-logo/9-2.png'
import Mitsui_logo from '../../images/new-client-logo/10-2.png'
import Radiant_world_logo from '../../images/new-client-logo/0-2.png'
import Rimeco_logo from '../../images/new-client-logo/11-2.png'
import Sims_logo from '../../images/new-client-logo/13-2.png'
import Stena_logo from '../../images/new-client-logo/14-2.png'
import Yucel_logo from '../../images/new-client-logo/17-2.png'
import WARD_logo from '../../images/new-client-logo/16-2.png'
import Atlas_logo from '../../images/new-client-logo/19-2.png'

import BNP_logo1 from '../../images/new-client-logo/20-1.png'
import CBank_logo1 from '../../images/new-client-logo/1-1.png'
import Gold_man1 from '../../images/new-client-logo/6-1.png'
import SGX_logo1 from '../../images/new-client-logo/12-1.png'
import Anglo_logo1 from '../../images/new-client-logo/18-1.png'
import CELSA_group1 from '../../images/new-client-logo/2-1.png'
import UOB_logo1 from '../../images/new-client-logo/15-1.png'
import CTIC_logo1 from '../../images/new-client-logo/3-1.png'
import emr_logo1 from '../../images/new-client-logo/4-1.png'
import Engelhart_logo1 from '../../images/new-client-logo/5-1.png'
import gsmt_logo1 from '../../images/new-client-logo/7-1.png'
import Kroman_logo1 from '../../images/new-client-logo/8-1.png'
import Megasa_logo1 from '../../images/new-client-logo/9-1.png'
import Mitsui_logo1 from '../../images/new-client-logo/10-1.png'
import Radiant_world_logo1 from '../../images/new-client-logo/0-1.png'
import Rimeco_logo1 from '../../images/new-client-logo/11-1.png'
import Sims_logo1 from '../../images/new-client-logo/13-1.png'
import Stena_logo1 from '../../images/new-client-logo/14-1.png'
import Yucel_logo1 from '../../images/new-client-logo/17-1.png'
import WARD_logo1 from '../../images/new-client-logo/16-1.png'
import Atlas_logo1 from '../../images/new-client-logo/19-1.png'
import { useState } from 'react'

const TrustedClient = () => {
  const [companylogos, setCompanyLogo] = useState([
    {
      primary: BNP_logo,
      secondary: BNP_logo1,
      hover: false
    },
    {
      primary: CBank_logo,
      secondary: CBank_logo1,
      hover: false
    },
    {
      primary: Gold_man,
      secondary: Gold_man1,
      hover: false
    },

    {
      primary: SGX_logo,
      secondary: SGX_logo1,
      hover: false
    },
    {
      primary: Anglo_logo,
      secondary: Anglo_logo1,
      hover: false
    },
    {
      primary: CELSA_group,
      secondary: CELSA_group1,
      hover: false
    },
    {
      primary: UOB_logo,
      secondary: UOB_logo1,
      hover: false
    },
    {
      primary: CTIC_logo,
      secondary: CTIC_logo1,
      hover: false
    },
    {
      primary: emr_logo,
      secondary: emr_logo1,
      hover: false
    },
    {
      primary: Engelhart_logo,
      secondary: Engelhart_logo1,
      hover: false
    },
    {
      primary: gsmt_logo,
      secondary: gsmt_logo1,
      hover: false
    },
    {
      primary: Kroman_logo,
      secondary: Kroman_logo1,
      hover: false
    },
    {
      primary: Megasa_logo,
      secondary: Megasa_logo1,
      hover: false
    },
    {
      primary: Mitsui_logo,
      secondary: Mitsui_logo1,
      hover: false
    },
    {
      primary: Radiant_world_logo,
      secondary: Radiant_world_logo1,
      hover: false
    },
    {
      primary: Rimeco_logo,
      secondary: Rimeco_logo1,
      hover: false
    },
    {
      primary: Sims_logo,
      secondary: Sims_logo1,
      hover: false
    },
    {
      primary: Stena_logo,
      secondary: Stena_logo1,
      hover: false
    },
    ,
    {
      primary: Yucel_logo,
      secondary: Yucel_logo1,
      hover: false
    },
    {
      primary: WARD_logo,
      secondary: WARD_logo1,
      hover: false
    },
    {
      primary: Atlas_logo,
      secondary: Atlas_logo1,
      hover: false
    }
  ])

  const onHover = index => {
    const main_image = document?.getElementById(`image${index}`)
    const secondryImage = document?.getElementById(`secimage${index}`)

    main_image.classList.add('main_image')
    secondryImage.classList.remove('hide-secondry')
  }
  const onOut = index => {
    const main_image = document?.getElementById(`image${index}`)
    const secondryImage = document?.getElementById(`secimage${index}`)

    main_image.classList.remove('main_image')
    secondryImage.classList.add('hide-secondry')
  }
  return (
    <section className='trusted-client'>
      <Container>
        <h1 className='text-center'>Trusted By Industry Leaders</h1>
        <Row>
          <Col xs={12}>
            <Marquee pauseOnHover={true} speed={30} className='trusted-client'>
              {companylogos?.map((item, i) => {
                return (
                  <div
                    className='logo-img'
                    onMouseEnter={() => {
                      onHover(i)
                    }}
                    onMouseLeave={() => {
                      onOut(i)
                    }}
                  >
                    {' '}
                    <img
                      onMouseEnter={e => {
                        e.target.src = item?.secondary
                      }}
                      onMouseLeave={e => {
                        e.target.src = item?.primary
                      }}
                      src={item?.primary}
                      id={`image${i}`}
                      alt='client-logo'
                    />
                    <img
                      src={item?.secondary}
                      id={`secimage${i}`}
                      className='hide-secondry'
                      alt='client-logo'
                    />
                  </div>
                )
              })}
            </Marquee>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TrustedClient
