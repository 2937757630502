// import { NavLink, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container'

import Innertopbanner from '../components/inner-topbanner/Innertopbanner'
import DataFeatures from '../components/data-features/DataFeatures'
import Services from '../components/services/Services'
import Demobanner from '../components/demobanner/Demobanner'

import SupplyDemand from '../images/product/Directory-1.svg'
import SatelliteData from '../images/product/Directory-2.svg'
import Miningdata from '../images/product/Directory-3.svg'
import interactive_Directory from '../images/inner-banner/interactiveDirectory.svg'
import React, { useEffect } from 'react'
const InteractiveDirectory = () => {
  useEffect(() => {
    window?.scrollTo({ top:0, left:0, behavior: "instant"})
  }, [])
  return (
    <section className='main'>
      <section className='main-banner inner-banner'>
        <Container className='text-center text-md-start'>
          <Innertopbanner
            header={'Interactive Directory'}
            pradetails={
              'Access the most extensive network of Scrap exporters & merchants in North America, the United Kingdom, Scandinavia, Baltic and North & Eastern Europe.'
            }
            image={
              <img
                className='innerbanner-img navigate_ineractive'
                src={interactive_Directory}
                alt='banner-graphics'
              />
            }
          />
        </Container>
      </section>

      <section className='solution data-features py-4 text-center'>
        <DataFeatures
          header={'Data and Features'}
          Pradetails={
            'This solution offers unparallel access to scrap exporters and merchants, providing comprehensive details and contact information for easy communication and business operations'
          }
        />
      </section>

      <section className='middle-top interactive_directory py-3'>
        <Container className='text-cetner text-md-start'>
          <Services
            image={SupplyDemand}
            header={'Extensive Network'}
            pra={
              'Through exhaustive primary research, we present over 800 recycling facilities across regions, with different capabilities.'
            }
          />
          <Services
            image={SatelliteData}
            header={'Unparallel Insights'}
            pra={
              'Discover granular details including group capacity, average shipment size, cargo type, equipment, materials processed, key personnel and contacts.'
            }
          />
          <Services
            image={Miningdata}
            header={'Find the Right Company'}
            pra={
              'The Interactive Directory makes it easy to quickly find companies by filtering location, type of material, facilities and volumes exported.'
            }
          />
        </Container>
      </section>

      <Demobanner />
    </section>
  )
}

export default InteractiveDirectory
