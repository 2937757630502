// import { NavLink, Link } from "react-router-dom";
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Integrations from '../integrations/Integrations'

import './datasources.css'

import Satellite from '../../images/methodology/Satellite.svg'
import SignalTower from '../../images/methodology/SignalTower.svg'
import MinningTruck from '../../images/methodology/MiningTruck.svg'
import SteelMill from '../../images/methodology/steelmillheasignature.svg'
import Building from '../../images/methodology/Building.svg'
import DataServer from '../../images/methodology/data-server.svg'
import Earth_mp_from from '../../images/inner-banner/Earth.svg'
import Desktop from '../../images/inner-banner/Desktop.svg'
import Dockship from '../../images/inner-banner/DockedShip.svg'
import whatsapp from '../../images/inner-banner/Whatsapp.svg'
import interactive from '../../images/inner-banner/interactiveDirectory.svg'
import LaptopExcel from '../../images/methodology/laptopexcel.svg'
import BloombergTerminal from '../../images/methodology/BloombergTerminal.svg'
import LaptopCoding from '../../images/methodology/laptopCoding.svg'
import Whatsapp from '../../images/methodology/Whatsapp1.svg'
import React from 'react'

const Datasources = ({integrationSecRef}) => {

  return (
    <section className='data-sources' id='ds-source'>
      <Container className='text-center'>
        <Row>
          <Col sm={12}>
            <div className='dataSources-list'>
              <Row className='justify-content-center'>
                <Col>
                  <div className='datasources-item'>
                    <img src={Satellite} alt='satelite' />
                    Satellite Images
                  </div>
                </Col>
                <Col>
                  <div className='datasources-item'>
                    <img src={SignalTower} alt='satelite' />
                    AIS
                  </div>
                </Col>
                <Col>
                  <div className='datasources-item'>
                    <img src={SteelMill} alt='satelite' />
                    Thermal
                  </div>
                </Col>
                <Col>
                  <div className='datasources-item'>
                    <img src={MinningTruck} alt='satelite' />
                    Customs
                  </div>
                </Col>
                <Col>
                  <div className='datasources-item'>
                    <img src={Building} alt='satelite' />
                    Partner Data
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className='text-center position-relative'>
            <img
              src={DataServer}
              alt='data-server'
              className='dataserver-img'
            />
            <div className='img-sec-content'>
              <h3>Gold Standard Tech Infrastructure</h3>
              <p>
                Our Company's tech stack is critical for managing our huge
                backend operations, which involve data storage, automations,
                data calculation, and security management.
              </p>
            </div>
            <div className='img-sec-content img-sec-content-right'>
              <h3>What is it built on?</h3>
              <p>
                <b>AWS</b> RDS <br />
                PostgreSQL <br />
                AWS EC2 <br />
                AWS Lambda <br />
                SendinBlue <br />
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className='dataSources-list product'>
              <Row className='justify-content-center'>
                <Col>
                  <Link to='/navigateEarth' class='datasources-item'>
                    <img src={Earth_mp_from} alt='satelite' />
                    <span className='me-3'>Earth</span>
                  </Link>
                </Col>
                <Col>
                  <Link to='/navigateMarine' class='datasources-item'>
                    <img src={Dockship} alt='satelite' />
                    Marine
                  </Link>
                </Col>
                <Col>
                  <Link to='/marketCompass' class='datasources-item'>
                    <img src={Desktop} alt='satelite' />
                    Market Compass
                  </Link>
                </Col>
                <Col>
                  <Link to='/naviWhatsapp' class='datasources-item'>
                    <img src={whatsapp} alt='satelite' />
                    NAVI
                  </Link>
                </Col>
                <Col>
                  <Link to='/interactiveDirectory' class='datasources-item'>
                    <img src={interactive} alt='satelite' />
                    Interactive Directory
                  </Link>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <section className='integrations' id='integration' ref={integrationSecRef}>
          <h2 >Integrations</h2>
          <Row className='align-items-center'>
            <Integrations
              id1='integration'
              image={LaptopExcel}
              header={'Excel Add-in'}
              pra={
                'No time to update several spreadsheets manually? Navigate Commodities proprietary Excel Add-In lets you instantly refresh your Excel models with Award-Winning Intelligence..'
              }
            />
          </Row>
          <Row className='align-items-center'>
            <Integrations
              id1='intg2'
              image={BloombergTerminal}
              header={'Bloomberg'}
              pra={
                'The Bloomberg Terminal offers unparalleled asset class market coverage from one device. Navigate Commodities exclusive data is now available via Bloomberg Terminal, expanding our reach to the business elite.'
              }
            />
          </Row>
          <Row className='align-items-center'>
            <Integrations
              id1='intg3'
              image={Whatsapp}
              header={'WhatsApp (NAVI)'}
              pra={
                "NAVI is the world's first AI commodities analytics bot for the Ferrous Dry Bulk markets on Whatsapp. On-demand access to trade flows and output trends for any nation, whether historical or seasonal patterns. NAVI never sleeps"
              }
            />
          </Row>
          <Row className='align-items-center'>
            <Integrations
              id1='intg4'
              image={LaptopCoding}
              header={'Application Programming Interface (API)'}
              pra={
                "Using our API, your developers can gain direct access to Navigate Commodities' data ecosystem and call the required data, whenever you need it. Supercharge your own applications & models with Navigate Commodities' Award-Winning intelligence."
              }
            />
          </Row>
        </section>
      </Container>
    </section>
  )
}

export default Datasources
