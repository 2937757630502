import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';

import Topbanner from "../components/top-banner/Topbanner";
import TrustedClient from "../components/client-carousel/client";
import Solutions from "../components/solution/Solution-sec";
import Services from "../components/services/Services";
import Tradeservices from "../components/services/Tradeservices";
import Testimonials from "../components/testimonials/Testimonial";
import Demobanner from "../components/demobanner/Demobanner";

import SupplyDemand from "../images/home/Supply-demand-b.svg";
import SatelliteData from "../images/home/SatelliteData-b.svg";
import Miningdata from "../images/home/Miningdata-b.svg";
import MachineLearning from "../images/home/machine-learning.svg";
import AISTracking from "../images/home/DockedShip.svg";
import SatelliteThermal from "../images/home/steelmillheat.svg";

const Home = ()=>{

    useEffect(() => {
        window?.scrollTo(0, 0)
    }, [])

    return(
    <section className="main">

        <Topbanner/>

        <TrustedClient/>

        <Solutions/>

        <section className="middle-top">
            <Container className = "text-cetner text-md-start">
                <Services image={SupplyDemand} header={'FIRST to find shifts in Supply & Demand balance'} pra = {'With our data, clients can make better decisions and react quickly to shifts in supply and demand, by having access to up-to-date and reliable intelligence.'}/>
                <Services image={SatelliteData} header={'Know your competitors, inside out'} pra = {`With our solutions, you can gain a complete understanding of your rivals' strategies, market positioning, and capacities, enabling you to make better decisions. `}/>
                <Services image={Miningdata} header={'Maximise profits in every deal'} pra = {'Make the most out of each trade when you know your partner’s current position with our extremely accurate intel.'}/>
            </Container>
        </section>

        <section className="middle-top middle-bottom">
            <Container className = "text-cetner text-md-start">
                <h1 className='text-center mb-3 mb-lg-5'>
                    Application of Space & Marine Tech to Commodity Trade
                </h1>
                <Tradeservices image={MachineLearning} header={'Satellite imagery & Machine Learning'} pra = {'Using AI and satellite technology to estimate stockpiles of dry bulk commodities with accuracy and speed.'} 
                button = {<><Link to = "/methodology" className = "btn-main">View Solution</Link></>}/>
                <Tradeservices image={AISTracking} header={'Satellite AIS Tracking'} pra = {'Analyzing satellite AIS data to determine cargo volumes, delivery times, and potential supply chain disruptions in Real Time.'} 
                button = {<><Link to = "/navigateMarine" className = "btn-main">View Solution</Link></>}/>
                <Tradeservices image={SatelliteThermal} header={'Satellite Thermal Imagery'} pra = {'Using satellite thermal imaging to track the temperature of blast furnaces & smelters to estimate their production levels an determine consumption of raw materials.'} 
                button = {<><Link to = "/navigateEarth" className = "btn-main">View Solution</Link></>}/>
            </Container>
        </section>

        <Testimonials/>

        <Demobanner/>

    </section>
    );
}

export default Home;