// import { NavLink, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container'
import React from 'react'
import Centertopbanner from '../components/center-top-banner/centertopbanner'
import DataSources from '../components/dataSources/datasources'
import Demobanner from '../components/demobanner/Demobanner'
import { useLocation, useParams } from 'react-router-dom'
import { useRef } from 'react'
const Methodology = () => {
  
  const { id } = useParams()
  const integrationSecRef = useRef();
  const {state}= useLocation();

  React.useEffect(() => {
    window.scrollTo({top:0, left:0 , behavior:'instant'})
  }, [])

  React.useEffect(() => {
    if (id != null && id != undefined ) {
      setTimeout(() => {
      integrationSecRef.current.scrollIntoView({ behavior: 'smooth' })
      }, 300);
    }
    else if (state?.fromlink=="viewsolution" )
    {
      setTimeout(() => {
      window?.scrollTo({ top : 1050 , left :0 ,behavior: 'smooth'})
      }, 300);
    }
    else{
      setTimeout(() => {
        window?.scrollTo({ top : 0 , left :0 ,behavior: 'smooth'})
        }, 300);
    }
  
  }, [id])

  return (
    <section className='main'>
      <section className='main-banner inner-banner center-top-banner ' >
        <Container className='text-center'>
          <Centertopbanner
            header={'Unbeatable Commodities Insights with Advance Data Methodology'}
            pradetails={'Tech stack for intelligent commodity trading'}
          />
        </Container>
      </section>

    <DataSources integrationSecRef={integrationSecRef}/>

      <Demobanner />
    </section>
  )
}

export default Methodology
