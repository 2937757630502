import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import React from 'react'
import Col from 'react-bootstrap/Col'
import { useParams } from 'react-router-dom'
import './about.css'
import AboutBanner from '../../images/about/about-banner.png'

const Ourstory = () => {
  const { id } = useParams()
    
  React.useEffect(() => {
    console.log(id)
    if (id != null && id != undefined) {
      document.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth' })
    }
  }, [id]);

  


  return (
    <section className='Ourstory' id='our-story1'>
      <section className='main-banner about-banner'>
        <Container>
          <Row className='align-items-center'>
            <Col md={6} className='mb-3 mb-md-0'>
              <h1>Our Story</h1>
              <p>
                Frustrated by the barriers to entry to track supply chains in
                real-time and commodity market opacity, the founding team
                started Navigate Commodities in 2020.
              </p>
              <p>
                The goal of the Navigate Commodities platform was to digitise
                the monitoring and tracking of bulk commodities in real-time and
                democratise investment banking-quality commodity intelligence so
                SMEs could compete with larger industry peers
              </p>
            </Col>
            <Col md={{ span: 5, offset: 1 }}>
              <img src={AboutBanner} className='w-100' alt='banner-img' />
            </Col>
          </Row>
        </Container>
      </section>

      <section className='ourstory_timeline position-relative'>
        <Container>
          <Row>
            <Col sm={12}>
              <div className='timeline-container position-relative'>
                <div className='timeline-content right'>
                  <h2>Apr 2020</h2>
                  <p>
                    Navigate Commodities was founded and officially registered
                    on ACRA
                  </p>
                </div>
                <div className='timeline-content left'>
                  <h2>Apr 2020</h2>
                  <p>
                    Navigate Commodities awarded Startup Founder Grant by
                    Enterprise Singapore
                  </p>
                </div>
                <div className='timeline-content right'>
                  <h2>Apr 2021</h2>
                  <p>Navigator/Compass Points was launched</p>
                </div>
                <div className='timeline-content left'>
                  <h2>Jun 2021</h2>
                  <p>Navigate Marine – Iron Ore launched</p>
                </div>
                <div className='timeline-content right'>
                  <h2>Feb 2022</h2>
                  <p>
                    Moved a bigger office to accommodate analyst & data
                    scientist
                  </p>
                </div>
                <div className='timeline-content left'>
                  <h2>Apr 2022</h2>
                  <p>
                    Voted Best Iron Ore Research for EnergyRisk Commodity
                    Rankings 2022
                  </p>
                </div>
                <div className='timeline-content right'>
                  <h2>Apr 2022</h2>
                  <p>Navigate Marine – Ferrous Scrap Launch</p>
                </div>
                <div className='timeline-content left'>
                  <h2>Sep 2022</h2>
                  <p>NAVI – WhatsApp Commodity Analyst AI Bot Launch</p>
                </div>
                <div className='timeline-content right'>
                  <h2>Jan 2023</h2>
                  <p>
                    Partners with Earth-I for satellite & remote sensing
                    capabilities
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  )
}

export default Ourstory
