import Home from './pages/Home'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'

import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './css/navigate-style.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import React, { Fragment } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import NavigateEarth from './pages/navigateEarth'
import NavigateMarine from './pages/navigateMarine'
import MarketCompass from './pages/marketCompass'
import NaviWhatsapp from './pages/naviWhatsapp'
import InteractiveDirectory from './pages/interactiveDirectory'
import RequestDemo from './pages/requestDemo'
import Methodology from './pages/methodology'
import About from './pages/about'
import Contact from './pages/contact'
import Blogs from './pages/blogs'
import Blogdetails from './pages/blog-details'
import Salverystatement from './pages/salvery-statement'
import Termcondiitons from './pages/term-condition'
import Privacypolicy from './pages/privacy-policy'
import Cookiespolicy from './pages/cookies-policy'
import DataCoverage from './pages/data-coverage'
import Insitemedia from './pages/insite-media'
import Mediacoveragedetails from './pages/insite-media-details'
import NotFound from './pages/404'
import Servererror from './pages/server-error'

function App () {
  return (
    <>
      <Fragment>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/navigateEarth' element={<NavigateEarth />} />
          <Route path='/navigateMarine' element={<NavigateMarine />} />
          <Route path='/marketCompass' element={<MarketCompass />} />
          <Route path='/naviWhatsapp' element={<NaviWhatsapp />} />
          <Route
            path='/interactiveDirectory'
            element={<InteractiveDirectory />}
          />
          <Route path='/requestDemo' element={<RequestDemo />} />
          {/* <Route path='methodology' element={<Methodology />} /> */}
          {['/methodology', '/methodology/:id']?.map(item => {
            return <Route path={item} element={<Methodology />} />
          })}
          {['/about', '/about/:id']?.map(item => {
            return <Route path={item} element={<About />} />
          })}

          <Route path='/contact' element={<Contact />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/blog-details/:id/:title' element={<Blogdetails />} />
          <Route path='/salvery-statement' element={<Salverystatement />} />
          <Route path='/term-condition' element={<Termcondiitons />} />
          <Route path='/privacy-policy' element={<Privacypolicy />} />
          <Route path='/cookies-policy' element={<Cookiespolicy />} />
          <Route path='/data-coverage' element={<DataCoverage />} />
          <Route path='/insite-media' element={<Insitemedia />} />
          <Route
            path='/media-details/:id/:title'
            element={<Mediacoveragedetails />}
          />
          <Route path='/server-error' element={<Servererror />} />
          <Route path='*' element={<NotFound />} />
        </Routes>

        <Footer />
      </Fragment>
    </>
  )
}
export default App
