import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./about.css";

import React from "react";
import Slider from "react-slick";

import CNBC from "../../images/about/CNBC.png";
import Rectangle from "../../images/about/Rectangle.png";
import Bloombe from "../../images/about/Bloombe.png";
import Techinasia from "../../images/about/Techinasia.png";
import Clock_ic from "../../images/about/bx-time-five.svg";
import MiningJournal from "../../images/about/miningJournal.png";
import CNN from "../../images/about/cnn.png";
import sourthchine from "../../images/about/sourthchine.png";

import Marquee from "react-fast-marquee";

const MediaCoverage = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 0,
    // speed: 2500,
    // cssEase:'linear',
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    nav: true,
    pauseOnHover: true,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          nav: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          nav: false,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 3,
          nav: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          nav: false,
          dots: false,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          nav: false,
          dots: false,
        },
      },
    ],
  };
  return (
    <section className="media-coverage">
      <Container fluid>
        <h2 className="text-center">Media Coverage</h2>
        <Row>
          <Col sm={12}>
            {/* <Slider {...settings}> */}
            <Marquee pauseOnHover={false} speed={30}>
              <div className="Media-partner">
                <div className="m-body">
                  <img src={Rectangle} alt="media_partner" />
                </div>
                <div className="m-footer">
                  <div className="f-header">Heading One</div>
                  <div className="f-content">
                    <img src={Clock_ic} alt="clock-icon" /> 21 Jan 2023
                  </div>
                </div>
              </div>

              <div className="Media-partner">
                <div className="m-body">
                  <img src={CNBC} alt="media_partner" />
                </div>
                <div className="m-footer">
                  <div className="f-header">Heading One</div>
                  <div className="f-content">
                    <img src={Clock_ic} alt="clock-icon" /> 21 Jan 2023
                  </div>
                </div>
              </div>

              <div className="Media-partner">
                <div className="m-body">
                  <img src={Bloombe} alt="media_partner" />
                </div>
                <div className="m-footer">
                  <div className="f-header">Heading One</div>
                  <div className="f-content">
                    <img src={Clock_ic} alt="clock-icon" /> 21 Jan 2023
                  </div>
                </div>
              </div>

              <div className="Media-partner">
                <div className="m-body">
                  <img src={Techinasia} alt="media_partner" />
                </div>
                <div className="m-footer">
                  <div className="f-header">Heading One</div>
                  <div className="f-content">
                    <img src={Clock_ic} alt="clock-icon" /> 21 Jan 2023
                  </div>
                </div>
              </div>

              <div className="Media-partner">
                <div className="m-body">
                  <img src={MiningJournal} alt="media_partner" />
                </div>
                <div className="m-footer">
                  <div className="f-header">Heading One</div>
                  <div className="f-content">
                    <img src={Clock_ic} alt="clock-icon" /> 21 Jan 2023
                  </div>
                </div>
              </div>

              <div className="Media-partner">
                <div className="m-body">
                  <img src={sourthchine} alt="media_partner" />
                </div>
                <div className="m-footer">
                  <div className="f-header">Heading One</div>
                  <div className="f-content">
                    <img src={Clock_ic} alt="clock-icon" /> 21 Jan 2023
                  </div>
                </div>
              </div>

              <div className="Media-partner">
                <div className="m-body">
                  <img src={CNN} alt="media_partner" />
                </div>
                <div className="m-footer">
                  <div className="f-header">Heading One</div>
                  <div className="f-content">
                    <img src={Clock_ic} alt="clock-icon" /> 21 Jan 2023
                  </div>
                </div>
              </div>
            </Marquee>
            {/* </Slider> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MediaCoverage;
