// import { NavLink, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container'

import Innertopbanner from '../components/inner-topbanner/Innertopbanner'
import DataFeatures from '../components/data-features/DataFeatures'
import Services from '../components/services/Services'
import Demobanner from '../components/demobanner/Demobanner'

import Marine_a from '../images/product/Marine-1.svg'
import Marine_b from '../images/product/Marine-2.svg'
import Marine_c from '../images/product/Marine-3.svg'
import DockedShip from '../images/inner-banner/DockedShip.svg'
import React, { useEffect } from 'react'
const NavigateMarine = () => {
  useEffect(() => {
    window?.scrollTo({ top:0, left:0, behavior: "instant"})
  }, [])

  return (
    <section className='main'>
      <section className='main-banner inner-banner'>
        <Container className='text-center text-md-start'>
          <Innertopbanner
            header={'Navigate Marine'}
            pradetails={
              'Utilizing Automated Identification Systems, track real-time trade flows, detect supply disruptions, and take the lead in identifying trade opportunities.'
            }
            image={
              <img
                className='innerbanner-img navigate_marine'
                src={DockedShip}
                alt='banner-graphics'
              />
            }
          />
        </Container>
      </section>

      <section className='solution data-features py-4 text-center'>
        <DataFeatures
          header={'Data and Features'}
          Pradetails={
            'condimentum mattis pellentesque id nibh tortor id aliquet lectus proin nibh nisl condimentum id venenatis a condimentum vitae sapien pellentesque habitant morbi tristique senectus et netus et malesuada fames ac'
          }
        />
      </section>

      <section className='middle-top navigate_marine py-3'>
        <Container className='text-cetner text-md-start'>
          <Services
            image={Marine_a}
            header={'Aggregated Real-time Trade Flows'}
            pra={
              'Monitor real-time movement of goods from port to port and berth to berth.'
            }
          />
          <Services
            image={Marine_b}
            header={'Understanding buyers'}
            pra={
              'Obtain a thorough understanding of the commodity buyers globally, including the quantities, origins & suppliers, and arrival dates.'
            }
          />
          <Services
            image={Marine_c}
            header={'Understanding exporters'}
            pra={
              'Gain a comprehensive understanding of a commodity exporter’s portfolio, including its clients, the quantities shipped, destinations, and shipping dates.'
            }
          />
        </Container>
      </section>

      <Demobanner />
    </section>
  )
}

export default NavigateMarine
