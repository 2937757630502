import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'

import './footer.css'

import Footerlogo from '../../images/logos/Navigate_Commodities_logo_WHT.png'

const Footer = () => {
  return (
    <section className='footer'>
      <Container>
        <Row className='top-footer justify-content-md-center'>
          <Col md={12} lg={2}>
            <img className='logo-img' src={Footerlogo} alt='footer-logo' />
          </Col>
          <Col xs={6} md={4} lg={2}>
            <h1>Products</h1>
            <Nav defaultActiveKey='/home' className='flex-column footer-nav'>
              <Link className='nav-link' to='/navigateEarth'>
                Navigate Earth
              </Link>
              <Link className='nav-link' to='/navigateMarine'>
                Navigate Marine
              </Link>
              <Link className='nav-link' to='/marketCompass'>
                Market Compass
              </Link>
              <Link className='nav-link' to='/naviWhatsapp'>
                NAVI WhatsApp AI
              </Link>
              <Link className='nav-link' to='/interactiveDirectory'>
                Interactive Directory
              </Link>
            </Nav>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <h1>Technology</h1>
            <Nav defaultActiveKey='/home' className='flex-column footer-nav'>
              <Link className='nav-link' to='/methodology'>
                Methodology
              </Link>
              <Link className='nav-link' to='/data-coverage'>
                Data Coverage
              </Link>
              <Link className='nav-link' to='/methodology/integrations'>
                Integrations
              </Link>
            </Nav>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <h1>About</h1>
            <Nav defaultActiveKey='/home' className='flex-column footer-nav'>
              <Link className='nav-link' to='/about/our-story1'>
                Our Story
              </Link>
              <Link className='nav-link' to='/about/leadership'>
                Our Leadership
              </Link>
              <Link className='nav-link' to='/about/company'>
                Our Company
              </Link>
              <Link className='nav-link' to='/about/our-achi'>
                Our Achievements
              </Link>
            </Nav>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <h1>Insights</h1>
            <Nav defaultActiveKey='/home' className='flex-column footer-nav'>
              <Link className='nav-link' to='/blogs'>
                Blog 
              </Link>
              <Link className='nav-link' to='insite-media'>
                Media Coverage
              </Link>
            </Nav>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <h1>Connect</h1>
            <Nav defaultActiveKey='/home' className='flex-column footer-nav'>
              <Link className='nav-link' to='/contact'>
                Contact Us
              </Link>
              <Link className='nav-link' to='/requestDemo'>
                Request a Demo
              </Link>
              <a
                className='nav-link'
                href='https://www.linkedin.com/company/navigate-commodities-pte-ltd/'
                target='_blank'
              >
                LinkedIn
              </a>
              <a
                className='nav-link'
                href='https://www.youtube.com/@navigatecommodities3727'
                target='_blank'
              >
                Youtube
              </a>
            </Nav>
          </Col>
        </Row>
        <Row className='py-3 py-lg-4 align-items-center'>
          <Col lg={6}>
            <p className='mb-0'>
              &copy; Navigate Commodities Pte. Ltd. 2020-2023. All Rights
              Reserved.
            </p>
          </Col>
          <Col lg={6}>
            <ul className='nav justify-content-end'>
              <li className='nav-item'>
                <Link to='/cookies-policy'>Cookies</Link>
              </li>
              <li className='nav-item'>
                <Link to='/term-condition'>Term and Conditions</Link>
              </li>
              <li className='nav-item'>
                <Link to='/privacy-policy'>Privacy Policy</Link>
              </li>
              <li className='nav-item'>
                <Link to='/salvery-statement'>Modern Slavery Statement</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Footer
