import { Link, Navigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import React, { useState } from 'react'

import Navigate_logo from '../../images/logos/Navigate_Commodities_logo_header.svg'

import Earth_icon from '../../images/navbar-icons/navigate-earth.svg'
import Marine_icon from '../../images/navbar-icons/marine.svg'
import WhatsApp_icon from '../../images/navbar-icons/WhatsApp.svg'
import Interacitve_icon from '../../images/navbar-icons/intractive.svg'
import Compass_icon from '../../images/navbar-icons/bx-compass.svg'
import Methodology_icon from '../../images/navbar-icons/methodology.svg'
import DataCoverage_icon from '../../images/navbar-icons/data-coverage.svg'
import Integrations_icon from '../../images/navbar-icons/integrations.svg'
import Our_story from '../../images/navbar-icons/our-story.svg'
import Leadership_icon from '../../images/navbar-icons/leadership.svg'
import Our_company from '../../images/navbar-icons/our-company.svg'
import Achievements_icon from '../../images/navbar-icons/achievements.svg'
import Blogs_icon from '../../images/navbar-icons/blogs.svg'
import Media_icons from '../../images/navbar-icons/Media.svg'
import Contact_icon from '../../images/navbar-icons/contact.svg'
import Demo_icon from '../../images/navbar-icons/Demo.svg'
import Linkedin_icons from '../../images/navbar-icons/linkedin.svg'
import Youtube_icons from '../../images/navbar-icons/youtube.svg'

import AngleDown_icons from '../../images/navbar-icons/angle-down.svg'

import './header.css'

const Header = () => {
  const [isActive, setActive] = useState(false)
  const [currentTab, setCurrentTab] = useState()
  const [isScroll, setIsScroll] = useState(false)
  const toggleClass = () => {
    setActive(!isActive)
    setCurrentTab(!currentTab)
  }

  window?.addEventListener('scroll', () => {
    if (window?.screenY > 5) {
      setIsScroll(true)
    } else {
      setIsScroll(false)
    }
  })

  return (
    <Navbar
      bg='white'
      expand='lg'
      className={isScroll ? 'top-navbar scroll-top-navbar ' : 'top-navbar'}
    >
      <Container>
        <div className='position-relative d-flex align-items-center justify-content-between w-100 d-lg-none'>
          <Navbar.Brand href='/' className='py-0'>
            <img src={Navigate_logo} alt='logo' />
          </Navbar.Brand>
          <a href='#' className='btn-default btn-default-mob'>
            Login
          </a>
          <Navbar.Toggle
            aria-controls='basic-navbar-nav'
            className={isActive ? 'open-active' : null}
            onClick={toggleClass}
          />
        </div>
        <Navbar.Brand href='/' className='d-none d-lg-block py-0'>
          <img src={Navigate_logo} alt='logo' />
        </Navbar.Brand>
        <Nav className='ms-lg-auto'>
          <Navbar.Collapse id='basic-navbar-nav'>
            <ul className='nav'>
              <li
                className='nav-item dropdown-mn'
                onClick={() => {
                  currentTab == 'Products'
                    ? setCurrentTab('')
                    : setCurrentTab('Products')
                }}
              >
                <Link to='#'>
                  Products <img src={AngleDown_icons} alt='Angle-down' />
                </Link>
                <div
                  className={`${
                    currentTab == 'Products' ? 'open' : ''
                  } dropdown-content`}
                >
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Earth_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/navigateEarth'>Navigate Earth</Link>
                        </h3>
                        <p>Commodity Tracking from Spce</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Marine_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/navigateMarine'>Navigate Marine</Link>
                        </h3>
                        <p>Monitoring Bulk Commodities At Sea</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Compass_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/marketCompass'>Market Compass</Link>
                        </h3>
                        <p>Dry bulk Commodity price forecasts and analytics.</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={WhatsApp_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/naviWhatsapp'>NAVI WhatsApp AI</Link>
                        </h3>
                        <p>
                          AI chatbot for on-demand trade & production analytics.
                        </p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Interacitve_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/interactiveDirectory'>
                            Interactive Directory
                          </Link>
                        </h3>
                        <p>
                          World's most complete directory of scrap exporters
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className='nav-item dropdown-mn'
                onClick={() => {
                  currentTab == 'Technology'
                    ? setCurrentTab('')
                    : setCurrentTab('Technology')
                }}
              >
                <Link to='#'>
                  Technology <img src={AngleDown_icons} alt='Angle-down' />
                </Link>
                <div
                  className={`${
                    currentTab == 'Technology' ? 'open' : ''
                  } dropdown-content`}
                >
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Methodology_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/methodology'>Methodology</Link>
                        </h3>
                        <p>
                          Under the hood of our robust commodity data machine
                        </p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Integrations_icon} alt='icons' />
                        </div>
                        <h3>
                         
                          <Link to="/methodology/integration">Integrations</Link>
                        </h3>
                        <p>Flexible data delivery options via integrations</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={DataCoverage_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/data-coverage'>Data Coverage</Link>
                        </h3>
                        <p>Current & Prospective Sectors Navigate Serves</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className='nav-item dropdown-mn'
                onClick={() => {
                  currentTab == 'About'
                    ? setCurrentTab('')
                    : setCurrentTab('About')
                }}
              >
                <Link to='#'>
                  About <img src={AngleDown_icons} alt='Angle-down' />
                </Link>
                <div
                  className={`${
                    currentTab == 'About' ? 'open' : ''
                  } dropdown-content`}
                >
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Our_story} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/about/our-story1' id='our-story'>
                            Our Story
                          </Link>
                        </h3>
                        <p>Our Past, Present & Future</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Leadership_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/about/leadership'>Our Leadership</Link>
                        </h3>
                        <p>The minds behind our success</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Our_company} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/about/company'>Our Company</Link>
                        </h3>
                        <p>
                          Strengthening our industry ties through partnerships
                        </p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Achievements_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/about/our-achi'>Our Achievements</Link>
                        </h3>
                        <p>Milestones & accomplishments</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className='nav-item dropdown-mn'
                onClick={() => {
                  currentTab == 'Insights'
                    ? setCurrentTab('')
                    : setCurrentTab('Insights')
                }}
              >
                <Link to='#'>
                  Insights <img src={AngleDown_icons} alt='Angle-down' />
                </Link>
                <div
                  className={`${
                    currentTab == 'Insights' ? 'open' : ''
                  } dropdown-content`}
                >
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Blogs_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/blogs'>Blog </Link>
                        </h3>
                        <p>Read Navigate's opinion piece</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Media_icons} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/insite-media'>Media Coverage</Link>
                        </h3>
                        <p>See where we were featured</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className='nav-item dropdown-mn'
                onClick={() => {
                  currentTab == 'Contact'
                    ? setCurrentTab('')
                    : setCurrentTab('Contact')
                }}
              >
                <Link to='#'>
                  Contact <img src={AngleDown_icons} alt='Angle-down' />
                </Link>
                <div
                  className={`${
                    currentTab == 'Contact' ? 'open' : ''
                  } dropdown-content`}
                >
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Contact_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/contact'>Contact Us</Link>
                        </h3>
                        <p>Let's start a conversation</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Demo_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/requestDemo'>Request a Demo</Link>
                        </h3>
                        <Link to='#'>Amet minim mollit</Link>
                        <Link to='#'>non deserunt</Link>
                        <Link to='#'>ullamco</Link>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Linkedin_icons} alt='icons' />
                        </div>
                        <h3>
                          <a
                            href='https://www.linkedin.com/company/navigate-commodities-pte-ltd/'
                            target='_blank'
                          >
                            LinkedIn
                          </a>
                        </h3>
                        <p>Join our LinkedIn Network</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Youtube_icons} alt='icons' />
                        </div>
                        <h3>
                          <a
                            href='https://www.youtube.com/@navigatecommodities3727'
                            target='_blank'
                          >
                            YouTube
                          </a>
                        </h3>
                        <p>Watch our Videos</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className='nav-item login-link'>
                <a href='https://members.navigatecommodities.com/' className='btn-default'> Login</a>
              </li>
              <li className='nav-item'>
                <Link to='/requestDemo' className='btn-main'>
                  Request Demo
                </Link>
              </li>
            </ul>
          </Navbar.Collapse>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default Header
